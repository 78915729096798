import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import Styles from '../styles'

const PortalRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Fragment>
        <main className="PageContent">
          <Component {...props} />
        </main>
        <style jsx global>{Styles}</style>
      </Fragment>
    )}
  />
)

PortalRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.object.isRequired,
  ]),
}

export default PortalRoute

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styles from './styles'

const Avatar = ({
  userInitial = 'S',
  link = '',
  summaryPage = false,
  isHeaderMenu = false,
  currentlySubscribed = false,
  subscriptionType = '',
}) => (
  <Fragment>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <a href={link} className={`avatar-button ${currentlySubscribed ? subscriptionType : ''} ${summaryPage ? 'summary' : ''}`}>{!isHeaderMenu && <div className="label"><p className="text">{currentlySubscribed ? 'STV Player +' : 'Free'}</p></div>}<span data-testid="initial">{userInitial}</span></a>
    <style jsx>{styles}</style>
  </Fragment>
)

Avatar.propTypes = {
  userInitial: PropTypes.string,
  link: PropTypes.string,
  summaryPage: PropTypes.bool,
  isHeaderMenu: PropTypes.bool,
  currentlySubscribed: PropTypes.bool,
  subscriptionType: PropTypes.string,
}

export default Avatar

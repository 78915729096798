import { getPlayerUrl } from '../../modules/utils'

const stvLinks = {
  title: 'STV',
  show: 'phone',
  items: [
    {
      text: 'News',
      url: getPlayerUrl('/news'),
    },
    {
      text: 'STV Appeal',
      url: 'https://www.stv.tv/appeal',
    },
    {
      text: 'Careers',
      url: `https://www.stvplc.tv/careers`,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      text: 'Advertise with STV',
      url: 'https://www.stvcommercial.tv/',
    },
    {
      text: 'STV Group PLC',
      url: 'https://www.stvplc.tv/',
    },
  ],
}

const getMoreLinks = {
  title: 'Get More',
  show: 'phone',
  items: [
    {
      text: 'STV Player+',
      url: getPlayerUrl('/player-plus'),
    },
    {
      text: 'TV Guide',
      url: getPlayerUrl('/tvguide/'),
    },
    {
      text: 'How to watch',
      url: `https://support.stv.tv/support/stv-player-supported-devices`,
    },
    {
      text: 'Competitions',
      url: `https://competitions.stv.tv`,
    },
  ],
}

const quickLinks = {
  title: 'Quick Links',
  show: 'phone',
  items: [
    {
      text: 'Parental controls',
      url: getPlayerUrl('/settings'),
    },
    {
      text: 'Help & support',
      url: `https://support.stv.tv/support`,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      text: 'TV activation code',
      url: `/activate`,
    },
    {
      text: 'Visually Signed',
      url: getPlayerUrl('/categories/visually-signed'),
    },
    {
      text: 'Audio Described',
      url: getPlayerUrl('/categories/audio-described'),
    },
  ],
}

const informationLinks = {
  title: 'Information',
  show: 'phone',
  items: [
    {
      text: 'About us',
      url: getPlayerUrl('/info/about'),
    },
    {
      text: 'Contact us',
      url: getPlayerUrl('/info/contact'),
    },
    {
      text: 'Privacy',
      url: `/terms-of-use/#privacy`,
    },
    {
      text: 'Terms of use',
      url: `/terms-of-use/`,
    },
    {
      text: 'Cookie policy',
      url: `/terms-of-use/#cookies`,
    },
  ],
}

const links = [
  stvLinks,
  quickLinks,
  getMoreLinks,
  informationLinks,
]

export default links

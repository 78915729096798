/* eslint-disable import/prefer-default-export */
import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { Button, Typography } from '@stvgroup/stv-ui-components'
import {
  closeButton,
  modalBody,
  modalButtons,
  modalLayout,
} from './styles'

export const CloseButton = forwardRef(({ onClick }, ref) => (
  <button
    type="button"
    className="modal-close"
    ref={ref}
    onClick={onClick}
  >
    Close
    <style jsx>{closeButton}</style>
  </button>
))

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export const ModalHeader = forwardRef(({ text, id }, ref) => (
  <Typography variant="h3" id="id" className="modal-header" ref={ref} data-testid="modal-header-text">
    { text }
  </Typography>
))

ModalHeader.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export const ModalBody = ({ children }) => (
  <div className="modal-body">
    { children }
    <style jsx>{modalBody}</style>
  </div>
)

ModalBody.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}

export const ModalButton = ({
  onClick = () => {},
  variant = 'primary',
  isWaiting = false,
  text,
  ...props
}) => (
  <Button
    className="modal-button"
    variant={variant}
    onClick={onClick}
    loading={isWaiting || props?.loading}
    {...props}
  >
    <span className="button-text" aria-live="polite">
      { text }
    </span>
  </Button>
)

ModalButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  variant: PropTypes.PropTypes.oneOf(['secondary', 'primary', 'warning']),
  isWaiting: PropTypes.bool,
  loading: PropTypes.bool,
}

export const ModalButtons = ({ children }) => (
  <div className="modal-buttons">
    { children }
    <style jsx>{modalButtons}</style>
  </div>
)

ModalButtons.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
}

export const ModalLayout = ({ children, className = '' }) => (
  <div className={className}>
    { children }
    <style jsx>{modalLayout}</style>
  </div>
)

ModalLayout.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any.isRequired,
  className: PropTypes.string, // eslint-disable-line
}

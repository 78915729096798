import React from 'react'
import PropTypes from 'prop-types'

const Avatar = ({ width = 18, height = 18 }) => (
  <svg width={width} height={height} viewBox="0 0 18 18">
    <path d="M18,16.875 L18,17.6220703 C18,17.8330078 17.8330078,18 17.6220703,18 L0.377929688,18 C0.166992188,18 0,17.8330078 0,17.6220703 L0,16.875 C0,13.1220703 6.75,13.8779297 6.75,10.8720703 C6.75,10.8720703 4.5,8.62207031 4.5,4.5 C4.5,2.02148438 6.52148438,0 9,0 C11.4785156,0 13.5,2.02148438 13.5,4.5 C13.5,8.62207031 11.25,10.8720703 11.25,10.8720703 C11.25,13.8779297 18,13.1220703 18,16.875 Z" id="user" fill="currentColor" />
  </svg>
)

Avatar.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
}

export default Avatar

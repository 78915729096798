import React, { useState } from 'react'
import ResetPassword from './ResetPassword'
import ResetPasswordSuccess from '../../components/ResetPasswordSuccess'
import { trackEvent } from '../../modules/dtm-helpers'

const ResetPasswordContainer = () => {
  const [page, setPage] = useState('')
  const handleResetPassword = () => {
    setPage('ResetPasswordSuccess')
    trackEvent('passwordResetForm', {})
  }

  if (page === 'ResetPasswordSuccess') {
    return (
      <ResetPasswordSuccess />
    )
  }
  return (
    <ResetPassword
      onSuccess={handleResetPassword}
    />
  )
}

export default ResetPasswordContainer


import React from 'react'
import PropTypes from 'prop-types'

const CrossIcon = ({
  width = 43,
  height = 43,
  title = 'close',
  color = 'currentColor',
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 44 43"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <path d="M13.082 30.3145L30.7108 12.6856" stroke="#F6F6F6" strokeWidth="2.24246" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.2969 12.9004L30.9257 30.5292" stroke="#F6F6F6" strokeWidth="2.24246" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)

CrossIcon.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  title: PropTypes.string,
  color: PropTypes.string,
}

export default CrossIcon

/* eslint-disable no-console */
import React, { useEffect } from 'react'
import cookies from 'js-cookie'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { Button } from '@stvgroup/stv-ui-components'
import Modal from '../../components/Modal'
import logo from '../../components/StvLogo/STVPlayerLogo.svg'
import styles from './styles'
import loginService from '../../initLoginService'
import { clearUser } from '../../actions/user_actions'
import { trackEvent } from '../../modules/dtm-helpers'
import { getCurrentDomain, getPlayerUrl } from '../../modules/utils'
import setPageName from '../../utils/pageName'

const TrackPage = (props) => {
  useEffect(() => {
    setPageName({
      page: 'onboarding',
      guid: 'backontrack',
    })
  }, [])

  const dispatch = useDispatch()
  cookies.set('STV_TRACK_PAGE', false, { expires: 365, domain: getCurrentDomain() })
  const handleLogOut = () => {
    const { location, push } = props.history
    loginService.logOut()
    trackEvent('logOutSuccess', {})
    dispatch(clearUser())
    push(`/?source=${encodeURIComponent(location.pathname)}`)
  }
  const closeUrl = getPlayerUrl()
  return (
    <Modal>
      {() => (
        <div className="success-modal">

          <div>
            <div className="image-wrapper">
              <img className="logo" src={logo} alt={logo} width={200} height={56} />
            </div>
            <div className="form-action">

              <a
                onClick={handleLogOut}
                onKeyPress={handleLogOut}
                href="#"
              >
                Logout
              </a>
            </div>
          </div>
          <div className="container-wrapper">
            <div className="header-wrapper">
              <p className="title">Let’s get you back on <span className="sub-titletext">track!</span></p>
              <p className="subTitle">We've made it easy for you to pick up where you left off. Simply select one of the following options.</p>

              <div className="terms-and-button-wrapper">

                <div className="button-wrapper">
                  <Button
                    className="success-dismiss"
                    variant="primary"
                    href="/plan?mode=USER_SIGNUP"
                  >
                    Continue with STV Player+
                  </Button>
                  <Button
                    className="success-secondary"
                    variant="secondary"
                    href={closeUrl}
                  >
                    Not right now
                  </Button>
                </div>

              </div>

            </div>
            <div className="displayImage" />
          </div>


          <style jsx>{styles}</style>
        </div>
      )}
    </Modal>

  )
}
TrackPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }),
  }).isRequired,
}
export default TrackPage

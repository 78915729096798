import css from 'styled-jsx/css'
import { colors } from '../../styles'

export default css`
.dialog-backdrop{
  position: fixed;
  z-index: 3;
  width: 100%;
  height: 100%;
  top:0;
  left: 0;
  background-color: rgba(21, 21, 21, 0.42);
  backdrop-filter: blur(4px);
}
.modal-basic{
  display: inline-flex;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
}
.modal{
  display: flex;
    flex-flow: column;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 0 1 auto;
}

.scroll {
  overflow-y: auto;
}
`

export const closeButton = css`
  .modal-close {
    background-color: ${colors.elevatedBg};
    width: 38px;
    height: auto;
    line-height: 40px;
    position: absolute;
    right: 20px;
    top:12px;
    content: url('/img/cross-icon-no-bg.svg');
    color: ${colors.primaryText};
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    font-weight: 700;
    outline: 2px;
    cursor: pointer;
    padding: 5px;
    border: 0;
    z-index: 3;
    border-radius: 50%;
    display: flex;
  }

  .modal-close:hover:after {
    border-radius: 100px;
    background: ${colors.baseBg};
  }
  .modal-close:after {
    font-size: 32px;
    content: url('/img/cross-icon-no-bg.svg');
    color: ${colors.primaryText};
    text-indent: 0;
    position: absolute;
    top: 0px;
    display: block;
    height: 100%;
    width: 100%;
    font-weight: 300;
  }
  @media (min-width: 767px){
    .modal-close {
      top:16px;
      width: 34px;
      height: 34px;
    }
  }
`
export const modalBody = css`
  .modal-body{
    display: flex;
    width: 496px;
    flex-direction: column;
    color: ${colors.primaryText};
    font-size: 18px;
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-align: left;
    width: 100%;
    background-color: ${colors.elevatedBg};
  }

  .modal-body :global(.medium),
  .modal-text.medium {
    font-size: 1em;
    margin: 0;
  }

  .modal-body :global(.large),
  .modal-text.large {
    font-size: 1.2em;
    font-weight: 700;
    margin: 1em 0;
  }

  .modal-body :global(ul) {
    margin: 24px 0 32px;
    padding: 0;
  }

  .modal-body :global(.list-items) {
    margin: 0;
  }

  .modal-body :global(li) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }


  .modal-body :global(li:nth-of-type(3) span::after,
  .terms p:nth-of-type(2)::before) {
    content: '**';
  }

  .modal-body :global(.tick) {
    margin: 10px 10px 0 0;
    color: ${colors.green};
  }

  .modal-body :global(.centre) {
    text-align: center;
  }
  @media (min-width: 600px) {

    .modal-body :global(.medium),
    .modal-text.medium {
      font-size: 1.5em;
    }

    .modal-body :global(.large),
    .modal-text.large {
      font-size: 1.5em;
      line-height: 2rem;
      text-align: left;
      font-weight: normal;
    }

    .modal-body :global(.list),
    .modal-text.list {
      font-size: 1.75em;
      margin: 0.5em 0 0.75em 0;
      text-align: left;
      font-weight: 700;
    }
  }
`

export const modalHeader = css`
  .modal-header {
    display: flex;
    width: 100%;
    height: auto;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    margin: 0;
    width: 100%;
  }
`
export const modalLayout = css`
.modal-layout {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  background-color: ${colors.elevatedBg};
  color: #F6F6F6;
  padding-bottom: 22px;
  display: inline-flex;
  padding: 16px;
  flex-direction: column;
  text-align: left;
  align-items: left;
  border-radius: 8px;
  left: 16px;
  right: 16px;
}

@media (min-width: 600px) {
  .modal-layout {
    width: 450px;
    max-width: 100%;
    padding: 24px;
    left: 0px;
    right: 0px;
  }
  .large-modal {
    max-width: 900px
  }
}
`
export const modalButtons = css`
.modal-buttons {
  margin-top: 12px;
  width: 100%;
  display: flex;
  justify-content: right;
  flex-direction: column;
}

:global(.small-modal .modal-buttons button) {
  font-size: 15px;
}

.modal-buttons :global(a) {
  text-decoration: none;
}

@media (max-width: 600px){
  .modal-buttons :global(.modal-button){
    margin: 0px 0px 8px 0px;
  }
}
`


import React from 'react'
import PropTypes from 'prop-types'
import LogoStyles from './styles'
import { getPlayerUrl } from '../../modules/utils'

const StvLogo = ({ center = false, hyperlinked = true }) => (
  <div>
    <a href={`${hyperlinked ? getPlayerUrl() : ''}`} className={`StvLogo ${center ? 'StvLogo--center' : ''} ${!hyperlinked ? 'nolink' : ''}`}>
      STV Login Portal
    </a>
    <style jsx>{LogoStyles}</style>
  </div>
)

StvLogo.propTypes = {
  center: PropTypes.bool,
  hyperlinked: PropTypes.bool,
}

export default StvLogo

import css from 'styled-jsx/css'
import { colors } from '../../styles'

export default css`
.IconLink {
  cursor: pointer;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  height: 32px;
  padding: 0.8rem;
  color: ${colors.primaryText};
  transform: scale(-1, 1);
}

.IconLink.nav-toggle {
  padding: 4px;
  border-radius: 8px;
}


.IconLink.nav-toggle.active {
  background-color: ${colors.ctvBg};
}

.IconLink.inactive {
  color: ${colors.white}
}
  .IconLink.search {
    /* Disable until search is fully built */
    display: none;
  }
  @media (min-width: 1131px) { 
    .IconLink {
      margin-top: 0.667rem;
      margin-bottom: 0.667rem;
    }
    .IconLink.active {
      padding-bottom: 1.167rem;
    }
    .IconLink.nav-toggle {
      display: none;
    }
  }
`

import css from 'styled-jsx/css'
import { colors } from '../../styles'

export default css`
  .PageFooter {
    background: ${colors.elevatedBg};
    margin: 0 auto;
    margin-top: 60px;
  }

  .inner {
    max-width: 1024px;
    margin: 0 auto;
  }

  .lower-footer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 1024px;
    margin: 0 auto;
  }

  .PageFooter > div {
    padding: 1rem;
  }

  ul, li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  hr {
    border: 0;
    margin: 0 1rem;
    height: 2px;
    background-color: ${colors.baseBg};
    max-width: 1024px;
  }


  .show-phone {
    display: block;
    margin: 0 0 1.5rem 0;
  }
  .show-tablet,
  .show-desktop {
    display: none;
  }

  .links {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .links ul {
    flex-basis: 50%;
  }

  .social {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${colors.primaryText};
  }

  .social .li {
    margin: 0 0.5rem;
  }
  .instagram {
    margin-right: 12px;
  }

  .links h4 {
    font-size: 18px;
    color: ${colors.primaryText};
    font-weight: 600;
  }

  .links li {
    font-size: 14px;
    padding-top: 12px;
  }

  .links li a {
    color: ${colors.primaryText};
    text-decoration: none;
    font-weight: 100;
  }

  .lower-footer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .lower-footer li a {

    color: ${colors.primaryText};
    text-decoration: none;
  }

  .legal p {
    margin-bottom: 1rem;
    text-align: center;
    color: ${colors.primaryText};
    font-size: 14px;
    -webkit-text-size-adjust: 100%;
  }

  .legal a {
    color: ${colors.bannerPurple};
    font-size: 14px;
    text-decoration: none;
  }

  @media (min-width: 600px) {
    .show-phone {
      margin: 0;
    }
    .show-tablet {
      display: block;
    }
    .links ul {
      flex-basis: 25%;
    }
    .mobile li {
      margin-left: 8px;
      margin-right: 8px;
    }
    .tv li,
    .second-screen li {
      margin: 8px;
    }
    .PageFooter > div {
      padding: 2rem 1rem;
    }
    hr {
      margin: auto;
    }
    .social {
      justify-content: flex-end;
      position: relative;
      left: 15px;
    }


  }

  @media (min-width: 800px) {
    .show-desktop {
      display: block;
    }
    .tv {
      display: flex;
      width: 100%;
      max-width: 820px;
    }
    .tv li,
    .second-screen li {
      margin: 16px;
    }
    .legal p {
      text-align: left;
    }
  }


  @media (min-width: 900px) {
    .mobile li,
    .second-screen li {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
`

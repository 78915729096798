import React from 'react'

const links = {
  help_and_support: <a href="https://support.stv.tv/support" target="_blank" rel="noreferrer">help and support</a>,
  terms_and_conditions: (args = {}) => {
    const fragment = args.planType === 'premier_sports' ? '#player-plus-premier-sports' : args.planType === 'plus' ? '#player-plus' : ''
    return <a href={`https://my.stv.tv/terms-of-use${fragment}`} target="_blank" rel="noreferrer">Terms and Conditions</a>
  },
  privacy_policy: <a href="https://my.stv.tv/terms-of-use/#privacy" target="_blank" rel="noreferrer">Privacy Policy</a>,
}

const Footnotes = {
  free_trial: '7-day free trial available to new subscribers only to STV Player+ plan. Not available with STV Player+ Premier Sports plan. Auto renews after free trial at selected price until cancelled.',
  ad_free: 'Excludes live channels and selected shows for contractual reasons.',
  ps_content: <>Includes Premier Sports channels and selected on-demand programmes; currently available to view only on the following platforms: web, iOS app, Android app, Apple TV, Google TV and Fire TV. The list of available platforms may be updated from time-to-time. Please check the {links.help_and_support} page</>,
  annual_discount: 'Yearly savings are compared to 12 months at the respective monthly price.',
  cancellation: 'Effective at the end of the subscription period.',
  card_terms: (args = {}) => <>By clicking "Agree & Subscribe", you agree to our {links.terms_and_conditions(args)}, you confirm that you have read our {links.privacy_policy}, and you authorise us to process recurring payments. You agree that you can access your subscription immediately and you acknowledge that you will lose any applicable statutory right to withdraw from this contract and receive a refund if you cancel in the cooling-off period.</>,
  google_pay_terms: (args = {}) => <>By clicking "Pay with Google Pay", you agree to our {links.terms_and_conditions(args)}, you confirm that you have read our {links.privacy_policy}, and you authorise us to process recurring payments. You have a legal right to withdraw from the contract and receive a refund within 14 days of starting this subscription by contacting us. Please note that no refunds will be issued for cancellations after the 14-day cooling-off period. *Yearly savings are compared to 12 months at the respective monthly subscription price.</>,
  apple_pay_terms: (args = {}) => <>By clicking "Pay with Apple Pay", you agree to our {links.terms_and_conditions(args)}, you confirm that you have read our {links.privacy_policy}, and you authorise us to process recurring payments. You have a legal right to withdraw from the contract and receive a refund within 14 days of starting this subscription by contacting us. Please note that no refunds will be issued for cancellations after the 14-day cooling-off period. *Yearly savings are compared to 12 months at the respective monthly subscription price.</>,
  paypal_terms: (args = {}) => <>To finish signing up to your subscription, click the "Pay with PayPal" button and log in to your PayPal account. By clicking "Pay with PayPal", you agree to our {links.terms_and_conditions(args)}, you confirm that you have read our {links.privacy_policy}, and you authorise us to process recurring payments. You agree that you can access your subscription immediately and you acknowledge that you will lose any applicable statutory right to withdraw from this contract and receive a refund if you cancel in the cooling-off period.</>,
}

export default Footnotes

/* eslint-disable react/prefer-stateless-function, react/static-property-placement */
import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { getCurrentPath } from '../../modules/utils'

import {
  isFireTv, setFireTvCookie, isGoogleTv, setGoogleTvCookie,
} from '../../utils/viaFireTvOrGoogleTvEntrypoint'

function withAuthentication(WrappedComponent) {
  return class extends React.Component {
    static propTypes = {
      user: PropTypes.shape({
        sub: PropTypes.string,
      }),
    }

    render() {
      if (isFireTv()) {
        setFireTvCookie()
      }
      if (isGoogleTv()) {
        setGoogleTvCookie()
      }
      const { user = { sub: '' } } = this.props
      const isAuthenticated = Boolean(user.sub)
      return (
        isAuthenticated
          ? <WrappedComponent {...this.props} />
          : <Redirect to={`/?source=${encodeURIComponent(getCurrentPath())}`} />
      )
    }
  }
}

const mapStateToProps = state => ({
  user: state.user,
})

const enhancedWithAuthentication = compose(
  connect(mapStateToProps, {}),
  withAuthentication,
)

export default enhancedWithAuthentication

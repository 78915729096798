import moment from 'moment'

function isUKPostcode(postcode) {
  // eslint-disable-next-line  prefer-regex-literals
  const re = new RegExp(/(GIR 0AA)|^((([ABCDEFGHIJKLMNOPRSTUWYZ][0-9][0-9]?)|(([ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY][0-9][0-9]?)|(([ABCDEFGHIJKLMNOPRSTUWYZ][0-9][ABCDEFGHJKSTUW])|([ABCDEFGHIJKLMNOPRSTUWYZ][ABCDEFGHKLMNOPQRSTUVWXY][0-9][ABEHMNPRVWXY]))))[0-9][ABDEFGHJLNPQRSTUWXYZ]{2})$/)
  return re.test(postcode.replace(/\s+/g, ''))
}

export function autoFormatDate(inputDate) {
  const input = `${inputDate}`
  const validSplitCharacters = ['.', '/', '-', ' ']
  let dateSegments = []
  const assumptions = {
    4: 'DMYY',
    5: 'DDMYY',
    6: 'DDMMYY',
    7: 'DDMYYYY',
    8: 'DDMMYYYY',
  }

  const calculateMostLikelyYear = (partialYear) => {
    const minAcceptedAge = 1
    const currentYear = (new Date()).getFullYear()
    const globalAverageAge = 28.4
    const currentCentury = Math.floor(currentYear / 100)
    const minViableCentury = currentCentury - 1

    const minPossibleAge = currentYear - parseInt((`${currentCentury + partialYear}`), 10)
    const maxPossibleAge = currentYear - parseInt((`${minViableCentury + partialYear}`), 10)
    const minPossibleAgeDifference = Math.abs(globalAverageAge - minPossibleAge)
    const maxPossibleAgeDifference = Math.abs(globalAverageAge - maxPossibleAge)
    const mostLikelyCentury = (
      minPossibleAgeDifference < maxPossibleAgeDifference && minPossibleAge > minAcceptedAge
    ) ? currentCentury : minViableCentury
    return `${mostLikelyCentury + partialYear}`
  }

  // First step is to figure out which part of the string represents day month of year.
  validSplitCharacters.forEach((splitter) => {
    const splitString = input.split(splitter)
    if (splitString.length > 1) dateSegments = splitString
  })

  // No valid split character was found, let's make some assumptions.
  if (!dateSegments.length) {
    // There's no way this could be a valid date, bail and fail.
    if (input.length < 4 || input.length > 8) {
      return '' // clears input field if year if no valid split characters
    }
    const assumedPattern = Array.from(assumptions[input.length])
    const dateIndexLookup = {
      D: 0,
      M: 1,
      Y: 2,
    }

    dateSegments = ['', '', '']

    assumedPattern.forEach((item, index) => {
      const segmentIndex = dateIndexLookup[item]
      dateSegments[segmentIndex] += input[index]
    })
  }

  // Format the segments in to DD/MM/YYYY
  const [day, month, year] = dateSegments.map(segment => ((segment.length === 1) ? `0${segment}` : `${segment}`))
  const correctDateFormat = !dateSegments.some(isNaN)
  if (year === undefined || !correctDateFormat) {
    return '' // clears input field if year is undefined or array contains anything thats not a number
  }
  const mostLikelyYear = (year.length === 2)
    ? calculateMostLikelyYear(year)
    : year
  return `${day}/${month}/${mostLikelyYear}`
}

export const getAllowedDateValidator = (value) => {
  const eighteenYears = moment().subtract(18, 'years')
  const ageLimit = moment().subtract(120, 'years')
  const birthday = moment(value, 'DD/MM/YYYY')

  if (eighteenYears.isBefore(birthday)) {
    return 'You need to be at least 18 years old to sign up.'
  }
  if (ageLimit.isAfter(birthday)) {
    return `This doesn't appear to be a valid date.`
  }

  if (eighteenYears.isAfter(birthday)) {
    return undefined // in Redux form land, undefined means valid ¯\_(ツ)_/¯
  }

  return `This doesn't appear to be a valid date.`
}

export const isRequired = value => (value || typeof value === 'number' ? undefined : 'This field is required.')
export const isDropdownRequired = value => (value === 'Title' ? 'This field is required' : undefined)
export const isMinLength = min => value => (value && value.length < min ? `Must be ${min} characters or more.` : undefined)
export const isMinLength2 = isMinLength(2)
export const isMinLength6 = isMinLength(6)
export const isEmailAddress = value => (
  value && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[\w-]{2,15}))$/i.test(value) // eslint-disable-line
    ? 'Invalid email address.'
    : undefined
)

export const isValidPostcode = value => (
  !isUKPostcode(value)
    ? 'This is not a valid postcode format.'
    : undefined
)

export const stripOutWhitespace = value => (
  value && value.replace(/\s+/gi, '')
)

export const stripTrailingWhitespace = value => (
  value && value.replace(/(^\s+|\s+$)/g, '')
)

// TODO: Add more tests
export const normalizeDateOfBirth = value => (
  value && value.replace(/[^0-9/]/g, '')
)

export const normalizePostcode = value => (
  value.length > 5
    ? stripTrailingWhitespace(value)
    : value
)

export const whitespaceOnlyForNames = value => (
  value.length <= 2 && !/^[^-\s][\w\s-]+$/i.test(value)
    ? 'Must be 2 characters or more.'
    : undefined
)

export const isPairingCode = value => (
  (value.length === 5 || value.length === 6)
    ? undefined
    : 'This is not a valid code.'
)

export const isMatchingPassword = (value, allValues) => (
  value !== allValues.passwordValue
    ? 'Passwords do not match.'
    : undefined
)

import css from 'styled-jsx/css'

export default css`
  :global(.PageContent, .ModalContent) {
    width: 376px;
    max-width: 100%;
    margin: 20px auto 0 auto;
    padding: 0 16px;
  }

  :global(.PageTitle) {
    text-align: center !important;
  }

  :global(.PageHeader) {
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
  }

  @media (min-width: 768px) {
    :global(.PageContent) {
      margin: 75px auto 0 auto;
    }
  }

  :global(.PageContent:has(.Article), .PageContent:has(.dashboard-error)) {
    width: 600px;
  }

`

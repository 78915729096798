import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import { Typography } from '@stvgroup/stv-ui-components'
import Styles from '../styles'
import PageFooter from '../../../components/PageFooter'
import HeaderMenu from '../../../components/HeaderMenu'

const DashboardRoute = ({ component: Component, title, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Fragment>
        <header className="PageHeader">
          <HeaderMenu />
        </header>
        <main className="PageContent">
          <Typography variant="h2" className="PageTitle">{title}</Typography>
          <Component {...props} />
        </main>
        <PageFooter />
        <style jsx global>{Styles}</style>
      </Fragment>
    )}
  />
)

DashboardRoute.propTypes = {
  component: PropTypes.oneOfType([
    PropTypes.func.isRequired,
    PropTypes.object.isRequired,
  ]),
  title: PropTypes.string.isRequired,
}

export default DashboardRoute

import cookies from 'js-cookie'
import { getPlayerSettingStatus } from '../../modules/utils'

const COOKIE_NAME = 'STV_FEATURE_FLAGS'

/**
 * Retrieves feature flags from cookie and API to check PS enabled status
 * @returns {Promise<boolean>} Returns true if PS is enabled via cookie or API, false otherwise
 */
export const isPsEnabled = async () => {
  try {
    // First check cookie value as it overrides API setting
    const cookieValue = cookies.get(COOKIE_NAME)
    if (cookieValue) {
      const flags = JSON.parse(decodeURIComponent(cookieValue))
      if (flags.psEnabled) return true
    }

    // If not enabled via cookie, check API setting
    try {
      const psEnabledFromApi = (await getPlayerSettingStatus('playerWebPremierSportOnboardingEnabled')).results
      return psEnabledFromApi === '1'
    } catch (apiError) {
      // Fall back to false if API call fails
      return false
    }
  } catch (error) {
    return false
  }
}

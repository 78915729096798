import { SET_PS_ENABLED } from '../constants/action_types'
import { isPsEnabled } from '../utils/featureFlags'

export function initializeFeatureFlags() {
  return async (dispatch) => {
    try {
      const psEnabled = await isPsEnabled()
      dispatch({
        type: SET_PS_ENABLED,
        payload: psEnabled,
      })
      return psEnabled
    } catch (error) {
      dispatch({
        type: SET_PS_ENABLED,
        payload: false,
      })
      return false
    }
  }
}

import css from 'styled-jsx/css'
import { colors } from '../../styles'

export default css`

.success-modal{
  background-color: ${colors.baseBg};
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  font-size: 18px;
  overflow: auto;
}

.button-wrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 8px;
}
.success-modal .subTitle{
  font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 30px;
text-align: center;
color: #F6F6F6;
}
.success-modal-inner{
  margin: auto;
  height: 100%;
  display: block;
  background: #212121;
  width: 100%;
  min-height: 750px;
}

.success-modal-contents {
  background-color: ${colors.baseBg};
  position: static;
  padding-top: 73px;
  margin: auto;
  height: 100%;
}

.success-modal a{
    height: 20px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #F6F6F6;
    line-height: 20px;
}
.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2%;
}

.success-modal p,
.success-modal span{
  color: ${colors.secondaryText};
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
}

.terms-and-button-wrapper {
  position: absolute;
  padding-top: unset;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: unset;
  bottom: 40px;
  width: 90vw;
  margin: auto;
}

#success-terms {
  text-align: left;
}
.header-wrapper {
    width: 250px;
    margin: auto;
  }

#success-header{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 46px;
    line-height: 50px;
    /* or 109% */

    display: flex;
    align-items: center;
    text-align: center;

    background: linear-gradient(81.24deg, #F544C6 29.81%, #FBA217 51.05%, #79CC32 74.63%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.success-modal :global(.success-dismiss){
  background: #9916C7;
  height: 44px;
  border-radius: 8px;
  color: white;
  font-weight: 400;
  font-size: 18px;
  border: none;
  width: 100%;
  line-height: 14px;
}
.success-modal :global(.success-secondary){
  height: 42px;
  border-radius: 8px;
  color: white;
  font-weight: 400;
  font-size: 18px;
  border: none;
  width: 100%;
  line-height: 14px;
}

.success-modal :global(.success-dismiss):hover {
  background: ${colors.darkPurple};
}

.success-modal :global(.success-dismiss-cross){
  background: unset;
}

@media (max-width: 767px){
  .success-modal-contents{
    padding-top: 115px;
}
.logo{
  height: 46px;
}
    .header-wrapper {
        width: 250px;
        margin: auto;
      }
      #success-header{
        display: block;
        margin-top: 24px;
      }

.success-modal .subTitle{
  margin: 24px 0px 0px;
}
}

@media (min-width: 767px){
  .success-modal{
    width: 100%;
    height: 100%;
    background-color: #151515;
  }
  .success-modal .subTitle{
    margin: 16px 0px;
  }
  .success-modal-inner{
    position: static;
    width: 720px;
    height: 100%;
  }

  .success-modal-contents {
    background-color: ${colors.elevatedBg};
    position: static;
    width: 80%;
    height: 100%;
  }

  .header-wrapper {
    width: 250px;
    margin: auto;
  }

  .logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .success-modal p,
  .success-modal span{
    color: ${colors.secondaryText};
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    margin: 0px;
  }

  .terms-and-button-wrapper {
    position: absolute;
    padding-top: unset;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: unset;
    bottom: 5%;
  }

  #success-terms {
    text-align: left;
  }

  #success-header{
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    padding-top: 40%;
    text-align: center;
  }
}

@media (min-width: 2000px){
  .success-modal-inner {
    width: 1080px;
  }

  #success-header {
    font-size: 61px;
    line-height: 67px;
  }

  .success-modal .subTitle {
    font-size: 32px;
    line-height: 40px;
    margin: 21px 0;
  }

  .terms-and-button-wrapper {
    width: 456px !important;
  }

  .success-modal p,
  .success-modal span{
    font-size: 16px;
    line-height: 21px;
  }

  .button-wrapper{
    gap: 13px;
    margin-top: 13px;
  }

  .success-modal :global(.success-dismiss){
    height: 59px;
    font-size: 24px;
    line-height: 24px;
  }
  .success-modal :global(.success-secondary){
    height: 56px;
    font-size: 24px;
    line-height: 24px;
  }
}

`

import css from 'styled-jsx/css'

export default css`

  .ResetPassword {
    background: #212121;
    width: 720px;
    max-width: 100%;
    margin: 0 auto;
    box-shadow: 0 0 32px 0 rgba(0,0,0,0.05);
  }

  .password-label {
    font-weight: 600;
    font-size: 18px;
    margin-bottom:
  }

  .stv-form-input--reset-password {
    margin-top: 6px;
  }
`

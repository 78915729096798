import React, {
  createContext, useContext, useEffect, useMemo,
} from 'react'
import PropTypes from 'prop-types'
import FOOTNOTES from '../../constants/footnotes'

const FootnotesContext = createContext({
  registerFootnote: () => {},
  unregisterFootnote: () => {},
  activeFootnotes: [],
  legend: true,
  args: {},
})

export const FootnotesProvider = ({ children, legend = true, args = {} }) => {
  const [activeFootnotes, setActiveFootnotes] = React.useState([])
  const registerFootnote = React.useCallback((key) => {
    setActiveFootnotes(prev => [...new Set([...prev, key])])
  }, [])

  const unregisterFootnote = React.useCallback((key) => {
    setActiveFootnotes(prev => prev.filter(k => k !== key))
  }, [])

  const contextValue = useMemo(() => ({
    registerFootnote,
    unregisterFootnote,
    activeFootnotes,
    legend,
    args,
  }), [registerFootnote, unregisterFootnote, activeFootnotes, legend, args])

  return (
    <FootnotesContext.Provider value={contextValue}>
      {children}
    </FootnotesContext.Provider>
  )
}

FootnotesProvider.propTypes = {
  children: PropTypes.node.isRequired,
  legend: PropTypes.bool,
  args: PropTypes.object,
}


export const WithFootnote = ({ footnoteKey, children }) => {
  const {
    registerFootnote, unregisterFootnote, activeFootnotes, legend,
  } = useContext(FootnotesContext)

  useEffect(() => {
    registerFootnote(footnoteKey)
    return () => unregisterFootnote(footnoteKey)
  }, [footnoteKey, registerFootnote, unregisterFootnote])

  const sortedFootnotes = [...activeFootnotes].sort(
    (a, b) => Object.keys(FOOTNOTES).indexOf(a) - Object.keys(FOOTNOTES).indexOf(b)
  )
  const footnoteIndex = sortedFootnotes.indexOf(footnoteKey)
  const asterisks = `${footnoteIndex + 1}` // '*'.repeat(footnoteIndex + 1)
  return (
    <span className="relative">
      {children}
      {legend && <sup>{asterisks}</sup>}
    </span>
  )
}

WithFootnote.propTypes = {
  footnoteKey: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export const FootnotesSection = () => {
  const { activeFootnotes, legend, args } = useContext(FootnotesContext)
  const sortedFootnotes = [...activeFootnotes].sort(
    (a, b) => Object.keys(FOOTNOTES).indexOf(a) - Object.keys(FOOTNOTES).indexOf(b)
  )
  return (
    <div className="footnotes">
      {sortedFootnotes.map((key, index) => {
        const footnote = FOOTNOTES[key]
        const footnoteContent = typeof footnote === 'function' ? footnote(args) : footnote
        return (
          <span key={key} className="footnote">
            {legend && (<sup>{index + 1}{' '}</sup>)}
            {footnoteContent}
            {' '}
          </span>
        )
      })}
    </div>
  )
}

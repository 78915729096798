import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'
import { autoFormatDate } from '../../modules/validated-fields'
import InputFieldStyles from './styles'
import { errors, tooltipStyles } from '../../styles'

class InputField extends Component {
  constructor(props) {
    super(props)
    this.state = {
      type: props.type || 'text',
    }
    this.showHideMethod = this.showHideMethod.bind(this)
  }

  showHideMethod(e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState(prevState => ({
      type: prevState.type === 'password'
        ? 'text'
        : 'password',
    }))
  }

  render() {
    const {
      id = '',
      input = {
        value: '',
        onBlur: () => {},
        name: '',
      },
      label = '',
      className = 'stv-form-input',
      description = '',
      dateFormat = false,
      tooltip = false,
      placeholders = '',
      placeholder = '',
      emailWrapper = false,
      activateCodeWrapper = false,
      passwordConfirmWrapper = false,
      personalDetailsEmailWrapper = false,
      autoFocus = false,
      showHide = false,
      adjustShowHide = false,
      showHideInsideField = false,
      disabled = false,
      value = '',
      enlargeText = false,
      autoCapitalize = 'none',
      autoCorrect = 'off',
      meta: {
        touched = null,
        error = 'error message',
        warning = 'warning message',
        valid = null,
        dirty = null,
      } = {},
    } = this.props

    const inputAdjusted = value === '' ? input : { ...input, value }
    const validCode = (value.length === 5 && input.name === 'activateDeviceValue')
    const fieldTouched = (dirty || valid) ? 'touched' : ''
    const hasPlaceholder = Boolean(placeholders)


    const classNames = [
      'stv-form-input',
      className,
      touched && error && 'has-error',
      valid && !dirty && !validCode ? 'not-changed' : null,
      touched && error && showHideInsideField && 'has-error-password',
      disabled && 'disabled',
      enlargeText && 'enlarge-text',
      hasPlaceholder && fieldTouched && 'input-touched',
    ]
      .filter(Boolean)
      .join(' ')

    const wrapperClassNames = [
      'stv-form-input-wrapper',
      emailWrapper && 'email-wrapper',
      passwordConfirmWrapper && 'password-confirm',
      personalDetailsEmailWrapper && 'personal-details-email-wrapper',
      activateCodeWrapper && '',
      className === 'stv-form-input name' && 'name',
    ]
      .filter(Boolean)
      .join(' ')

    const showHideClasses = [
      'password-show',
      showHideInsideField && 'password-show-inside-field',
      adjustShowHide && 'adjust-show-hide',
      this.state.type !== 'password' && 'hide',
    ]
      .filter(Boolean)
      .join(' ')

    const proxyOnBlur = evt => input.onBlur(autoFormatDate(input.value))
    const dobValidator = {
      ...(dateFormat && { onBlur: proxyOnBlur, type: 'number' }),
    }
    return (
      <div className={wrapperClassNames}>
        <div>
          {label && <label className="stv-form-label" htmlFor={label}>{label}</label>}
          {tooltip && (
            <div className="tooltip-wrapper">
              <Tooltip
                position="bottom"
                trigger="click focus"
                className="tooltip"
                style={{
                  display: 'inline-block',
                }}
                html={(
                  <div className="tooltip-content">
                    <p className="stv-input-description">There are some shows on the STV Player that you can only watch if you
                      live in one of our license regions.
                      Your postcode is a simple and easy way for us to check this.
                    </p>
                    <div className="stv-form-button">Okay</div>
                  </div>
                )}
              >
                <p className="tooltip-text">i</p>
              </Tooltip>
            </div>
          )}
          <div className="input-wrapper">
            <input
              {...inputAdjusted}
              {...dobValidator}
              id={id}
              type={this.state.type}
              className={classNames}
              autoFocus={autoFocus}
              disabled={disabled}
              placeholder={placeholder}
              autoCapitalize={autoCapitalize}
              autoCorrect={autoCorrect}
              data-testid={input.name}
            />
            <label className={`top-placeholder ${fieldTouched}`} htmlFor={id}>{placeholders}</label>
            {(showHide || showHideInsideField) && (
              // eslint-disable-next-line jsx-a11y/anchor-has-content
              <a
                className={showHideClasses}
                onClick={this.showHideMethod}
                role="none"
                onKeyPress={this.showHideMethod}
              />
            )}
          </div>

        </div>
        {(description && (
          <div className="small-text-wrapper">
            <span className="under-text">{description}</span>
          </div>
        ))}
        <div className="error-wrapper" style={(touched && error) ? { paddingBottom: '32px' } : { paddingBottom: '0px' }}>
          {(touched) || (error === 'This password does not match our records.') ? (
            (error
            && (
              <span
                className="error"
                dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
                  __html: error,
                }}
              />
            ))
              || (warning && <span className="error">{warning}</span>)
          ) : null}
        </div>
        <style jsx>{InputFieldStyles}</style>
        <style jsx>{errors}</style>
        <style jsx>{tooltipStyles}</style>
      </div>
    )
  }
}

export default InputField

InputField.propTypes = {
  id: PropTypes.string,
  input: PropTypes.shape({
    value: PropTypes.string,
    onBlur: PropTypes.func,
    name: PropTypes.string,
  }),
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  placeholders: PropTypes.string,
  dateFormat: PropTypes.bool,
  tooltip: PropTypes.bool,
  showHide: PropTypes.bool,
  showHideInsideField: PropTypes.bool,
  adjustShowHide: PropTypes.bool,
  emailWrapper: PropTypes.bool,
  personalDetailsEmailWrapper: PropTypes.bool,
  passwordConfirmWrapper: PropTypes.bool,
  activateCodeWrapper: PropTypes.bool,
  autoFocus: PropTypes.bool,
  enlargeText: PropTypes.bool,
  placeholder: PropTypes.string,
  autoCapitalize: PropTypes.string,
  autoCorrect: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
    warning: PropTypes.string,
    dirty: PropTypes.bool,
    valid: PropTypes.bool,
  }),
  disabled: PropTypes.bool,
  value: PropTypes.string,
}


import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styles from './styles'
import Avatar from '../Avatar'

const AccountSummary = ({
  userInitial,
  userName,
  currentlySubscribed = false,
  vipOptIn = false,
  border = true,
}) => (
  <Fragment>
    <div className={`account-summary ${border ? 'border' : 'no-border'}`}>
      <div className="avatar-container">
        <Avatar userInitial={userInitial} />
      </div>
      <div className="account-details">
        <div className="account-name"><p>{userName}</p></div>
        <div className="account-status">
          <div className={`player-plus ${vipOptIn ? 'has-bullet' : ''}`}>
            <p>{currentlySubscribed ? 'STV Player+' : 'Free Plan'}</p>
          </div>
          {vipOptIn && (
            <div className="vip" />
          )}
        </div>
      </div>
    </div>
    <style jsx>{styles}</style>
  </Fragment>
)

AccountSummary.propTypes = {
  userInitial: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  currentlySubscribed: PropTypes.bool,
  vipOptIn: PropTypes.bool,
  border: PropTypes.bool,
}

export default AccountSummary

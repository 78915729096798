import css from 'styled-jsx/css'
import { colors } from '../../styles'

export default css`
  .stv-input-description {
    margin-top: 5px;
    color: ${colors.secondaryText};
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    display: block;
    text-align: left;
  }

  .pad-top {
    margin-top: 40px;
  }
`

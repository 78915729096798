import React, { useEffect } from 'react'
import { Button } from '@stvgroup/stv-ui-components'
import StvLogo from '../StvLogo'
import { getPlayerUrl } from '../../modules/utils'
import setPageName from '../../utils/pageName'
import ResetPasswordSuccessStyles from './styles'

const ResetPasswordSuccess = () => {
  const closeUrl = getPlayerUrl()

  useEffect(() => {
    setPageName({
      page: 'login',
      guid: 'passwordchanged',
    })
  }, [])

  return (
    <div className="FormWrapper">
      <div className="content-wrapper">
        <div className="form-head">
          <StvLogo center />
        </div>
        <div className="form-content">
          <div className="ResetPasswordSuccess">
            <h2 className="big-heading">Let&apos;s get streaming!</h2>
            <h4 className="sub-heading"><span className="success-icon">*</span> Your password was changed.</h4>
            <div className="button-wrapper">
              <Button
                href={closeUrl}
              >
                Let's go
              </Button>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{ResetPasswordSuccessStyles}</style>
    </div>
  )
}

export default ResetPasswordSuccess

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import cookies from 'js-cookie'
import { ClientOnly } from '../SSRSwitch'
import loginService from '../../initLoginService'
import { trackEvent } from '../../modules/dtm-helpers'
import { Avatar as AvatarIcon, CrossIcon } from '../icons'
import getLoggedOutUrl from '../../utils/getLoggedOutUrl'
import { getPlayerUrl } from '../../modules/utils'
import styles from './styles'
import AccountSummary from '../AccountSummary'
import Avatar from '../Avatar'

const HeaderLoginLink = ({
  currentDomain,
  currentUrl,
  history,
  user,
  clearUser,
  isLoggedIn = true,
}) => {
  const [menuIsActive, setMenuIsActive] = useState(false)
  const [isVip, setIsVip] = useState(false)

  const isSubscribed = user.subscription?.info?.currentlySubscribed
  const subscriptionType = user.subscription?.info?.subscriptionType
  const userLoading = !user.subscription?.info
  const userInitial = user.given_name ? user.given_name.charAt(0) : ''
  const userName = user.given_name ? (user.family_name ? `${user.given_name} ${user.family_name}` : user.given_name) : ''

  useEffect(() => {
    setIsVip(cookies.get('STV_VIP_OPTIN') === 'true')
  }, [])

  useEffect(() => {
    if (window && window.innerWidth < 768) {
      if (menuIsActive) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = null
      }
    }
  }, [menuIsActive])

  const logOut = () => {
    const { location, push } = history
    loginService.logOut()
    trackEvent('logOutSuccess', {})
    clearUser()
    push(`/?source=${encodeURIComponent(location.pathname)}`)
  }

  const onBlur = (e) => {
    if (!e.currentTarget.contains(e.relatedTarget)) {
      if (window && window.innerWidth > 767) {
        closeMenu()
      }
    }
  }

  const closeMenu = () => setMenuIsActive(false)

  const profileHost = getLoggedOutUrl(currentDomain)

  if (Object.keys(user).length === 0) return null

  return (
    <ClientOnly>
      <div className={`Login ${menuIsActive ? 'active' : ''}`} onBlur={onBlur}>
        {!isSubscribed && !userLoading && (
          <div className="player-plus">
            <a href={getPlayerUrl('/player-plus')} onClick={() => { trackEvent('PplusNavUpgrade', { pplus_nav_upgrade: true }) }}>
              Subscribe to <strong>STV Player+</strong>
            </a>
          </div>
        )}
        {
          isLoggedIn && (
            <Fragment>
              <div className={`avatar-outer ${menuIsActive ? 'active' : 'inactive'}`}>
                <div className="avatar-container">
                  <Avatar
                    userInitial={userInitial}
                    link={getPlayerUrl('/account')}
                    currentlySubscribed={isSubscribed}
                    isHeaderMenu
                    subscriptionType={subscriptionType}
                  />
                </div>
              </div>
              <div className={`menu-container ${menuIsActive ? 'active' : ''}`}>
                <div className="menu-header">
                  <AccountSummary
                    userInitial={userInitial}
                    userName={userName}
                    currentlySubscribed={isSubscribed}
                    vipOptIn={isVip}
                  />
                  <div className="close-icon-wrapper">
                    <button
                      className="close-icon button-reset"
                      onClick={closeMenu}
                      type="button"
                      id="close-button"
                    >
                      <CrossIcon width={43} height={43} color="#F6F6F6" />
                    </button>
                  </div>
                </div>
                <div className="menu-body">
                  <ul className="accountMenu">
                    <li className="menu-item">
                      <a className="account-menu-link" href={getPlayerUrl()}>STV Player</a>
                    </li>
                    <li className="menu-item">
                      <a className="account-menu-link" href="/plan">{`${isSubscribed ? 'Plan Details' : 'Subscribe to STV Player+'}`}</a>
                    </li>
                    <li className="menu-item">
                      <a className="account-menu-link" href="/activate">Activate Your TV</a>
                    </li>
                    <li className="menu-item">
                      <a className="account-menu-link" href={getPlayerUrl('/settings')}>Parental Controls</a>
                    </li>
                    <li className="menu-item">
                      <button
                        className="account-menu-link"
                        type="button"
                        onClick={logOut}
                      >
                        Sign Out
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </Fragment>
          )
        }
        {
          isLoggedIn === false && (
            <Fragment>
              <div className="avatar signedOut"><AvatarIcon /></div>
              <a href={`${profileHost}?source=${encodeURIComponent(currentUrl)}`}>
                Sign in <span className="accountText">/Join</span>
              </a>
            </Fragment>
          )
        }
        <style jsx>{styles}</style>
      </div>
    </ClientOnly>
  )
}

HeaderLoginLink.propTypes = {
  currentDomain: PropTypes.string.isRequired,
  currentUrl: PropTypes.string.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }),
  }).isRequired,
  user: PropTypes.shape({
    given_name: PropTypes.string,
    family_name: PropTypes.string,
    subscription: PropTypes.shape({
      info: PropTypes.object,
    }),
  }).isRequired,
  clearUser: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool,
}

export default HeaderLoginLink
